import React from 'react';

const RadarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <filter id="21ni9avxza">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.537255 0 0 0 0 0.584314 0 0 0 0 0.627451 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#21ni9avxza)" transform="translate(-74 -146)">
        <g>
          <path
            fill="#8995a0"
            d="M10.687.277C12.79 2.292 14 5.053 14 8.002c0 2.946-1.208 5.706-3.308 7.72-.394.378-1.023.37-1.406-.018s-.374-1.008.02-1.385c1.72-1.65 2.706-3.905 2.706-6.317 0-2.414-.988-4.67-2.71-6.32-.394-.377-.403-.997-.02-1.385.382-.388 1.011-.397 1.405-.02zM7.796 3.27C9.196 4.503 10 6.191 10 7.992c0 1.81-.812 3.506-2.224 4.741-.415.363-1.078.355-1.48-.02-.403-.374-.393-.971.022-1.334 1.01-.884 1.589-2.092 1.589-3.387 0-1.287-.574-2.49-1.575-3.374-.413-.364-.42-.961-.017-1.334.405-.373 1.067-.38 1.48-.015zM4 6c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
            transform="translate(74 146)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default RadarIcon;
